export default defineNuxtRouteMiddleware((to, _from) => {
  const { isApp, loggedIn } = storeToRefs(useAuthStore());
  const { userData } = storeToRefs(useUserStore());

  if (loggedIn.value) {
    const userType = userData.value.type;
    const postLoginPage = userType === 'shipper' ? '/dashboard/post-load' : '/dashboard/loads';
    return navigateTo(postLoginPage);
  }

  if (isApp.value && !['/login', '/register', '/forgot-password', '/reset-password'].includes(to.fullPath)) {
    return navigateTo('/login');
  }
});
